import { FC } from "react";
import {
	Card,
	CardProps,
	styled,
} from "@mui/material";

// ===============================================
interface NavCardProps
	extends CardProps {
	// hoverEffect?: boolean;
}
// ===============================================

const NavCard = styled<
	FC<NavCardProps>
>(
	({
		// hoverEffect,
		children,
		...rest
	}) => (
		<Card {...rest}>{children}</Card>
	)
)<NavCardProps>(
	(
		{
			// theme,
			// hoverEffect
		}
	) => ({
		overflow: "unset",
		borderRadius: "8px",
		transition: "all 250ms ease-in-out",
		// "&:hover": {
		// 	...(hoverEffect && {
		// 		boxShadow: theme.shadows[3],
		// 	}),
		// },
	})
);

export default NavCard;
