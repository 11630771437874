import { Skeleton } from "@mui/material";
import { FlexBox } from "components/flex-box";
import { useNavigation } from "contexts/NavigationContext";
import useFashionPreference from "hooks/useFashionPreference";
import dynamic from "next/dynamic";
import Link from "next/link";
import { FC } from "react";
import { SECTION_ROOT } from "utils/config";

const DynamicButton = dynamic(
	() => import("components/BazaarCta"),
	{
		ssr: false,
		loading: () => (
			<Skeleton
				variant='rectangular'
				width={100}
				height={30}
			/>
		),
	}
);

// ==========================================================
export type MainSectionsMenuProps = {};
// ==========================================================

const MainSectionsMenu: FC<
	MainSectionsMenuProps
> = ({}) => {
	const { mainSections } =
		useNavigation();

	const { fashionPreference } =
		useFashionPreference();

	return (
		<FlexBox gap={1}>
			{mainSections?.map((section) => {
				const isSelected =
					fashionPreference?.id ===
					section.id;
				return (
					<DynamicButton
						key={section.id}
						fullWidth={false}
						size='small'
						variant={
							isSelected
								? "contained"
								: "text"
						}
						color={
							isSelected
								? "dark"
								: undefined
						}
						LinkComponent={Link}
						href={`${SECTION_ROOT}/${section.handle}`}>
						{section.name}
					</DynamicButton>
				);
			})}
		</FlexBox>
	);
};

export default MainSectionsMenu;
