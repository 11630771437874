"use client";
import { KeyboardArrowDown } from "@mui/icons-material";
// import ArrowRight from "@mui/icons-material/ArrowRight";
import {
	Box,
	MenuItem,
	styled,
} from "@mui/material";
import { FlexBox } from "components/flex-box";
import { NavLink } from "components/nav-link";
import { NavList } from "models/NavList.model";
import BazaarCard from "./BazaarCard";
import MegaMenu from "./navbar/MegaMenu";
import NavCard from "./navbar/NavCard";
import Link from "next/link";

// const common css style
const navLinkStyle = {
	cursor: "pointer",
	transition: "color 150ms ease-in-out",
	"&:hover": { color: "primary.main" },
	"&:last-child": { marginRight: 0 },
};
// style components
const StyledNavLink = styled(NavLink)({
	...navLinkStyle,
});

const ParentNav = styled(Box)(
	({ theme }) => ({
		"&:hover": {
			color: theme.palette.primary.main,
			"& > .parent-nav-item": {
				display: "block",
			},
		},
	})
);

const ParentNavItem = styled(Box)(
	({ theme }) => ({
		top: 0,
		zIndex: 5,
		right: "100%",
		paddingLeft: 8,
		display: "none",
		position: "absolute",
		[theme.breakpoints.down(1640)]: {
			right: "100%",
			left: "auto",
			paddingRight: 8,
		},
	})
);

const ChildNavsWrapper = styled(Box)({
	zIndex: 5,
	right: "0",
	top: "100%",
	display: "none",
	position: "absolute",
	// transform: "translate(-50%, 0%)",
});

const renderNestedNav = ({
	list = [],
	isRoot = false,
}: {
	list?: any[];
	isRoot?: boolean;
}) => {
	if (!list.length) return null;
	return list.map(
		(nav: NavList, i: number) => {
			if (!nav) return null;
			if (isRoot) {
				// show megamenu
				if (nav.megaMenu) {
					return (
						<MegaMenu
							key={nav.title + i}
							title={nav.title}
							url={nav.url}
							menuList={nav.child}
						/>
					);
				}

				if (
					nav.child &&
					nav.child.length > 0
				) {
					return (
						<FlexBox
							key={nav.title}
							alignItems='center'
							position='relative'
							flexDirection='column'
							sx={{
								"&:hover": {
									"& > .child-nav-item":
										{
											display: "block",
										},
								},
							}}>
							<FlexBox
								alignItems='flex-end'
								gap={0.3}
								sx={navLinkStyle}>
								{nav.title}{" "}
								<KeyboardArrowDown
									sx={{
										color: "grey.500",
										fontSize: "1.1rem",
									}}
								/>
							</FlexBox>

							<ChildNavsWrapper className='child-nav-item'>
								<NavCard
									elevation={3}
									sx={{
										mt: 2.5,
										py: 1,
										width:
											"fit-content",
									}}>
									{renderNestedNav({
										list: nav.child,
										isRoot: false,
									})}
								</NavCard>
							</ChildNavsWrapper>
						</FlexBox>
					);
				}
				if (nav.url) {
					return (
						<StyledNavLink
							href={nav.url}
							key={nav.title}>
							{nav.title}
						</StyledNavLink>
					);
				}
			} else {
				if (
					nav.child &&
					nav.child.length > 0
				) {
					return (
						<ParentNav
							position='relative'
							minWidth='230px'
							key={nav.title}>
							<MenuItem color='grey.700'>
								<Box
									flex='1 1 0'
									component={Link}
									href={nav.url}>
									{nav.title}
								</Box>
								{/* Workaround to solve third level not rendred */}
								{/* <ArrowRight fontSize='small' /> */}
							</MenuItem>

							<ParentNavItem className='parent-nav-item'>
								<BazaarCard
									sx={{
										py: "0.5rem",
										minWidth: "230px",
									}}
									elevation={3}>
									{renderNestedNav({
										list: nav.child,
										isRoot: false,
									})}
								</BazaarCard>
							</ParentNavItem>
						</ParentNav>
					);
				}
				if (nav.url) {
					return (
						<NavLink
							href={nav.url}
							key={nav.title}>
							<MenuItem>
								{nav.title}
							</MenuItem>
						</NavLink>
					);
				}
			}
		}
	);
};

export default renderNestedNav;
