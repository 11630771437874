"use client";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
	Box,
	Grid,
	List,
	ListItem,
	styled,
} from "@mui/material";
import BazaarCard from "components/BazaarCard";
import { H5 } from "components/Typography";
import { FlexRowCenter } from "components/flex-box";
import { NavLink } from "components/nav-link";
import { Navs } from "models/NavList.model";
import { FC } from "react";

// style components
const Wrapper = styled(Box)(
	({ theme }) => ({
		cursor: "pointer",
		position: "relative",
		transition:
			"color 150ms ease-in-out",
		":hover": {
			color: theme.palette.primary.main,
			"& .menu-list": {
				display: "block",
			},
		},
	})
);

const MenusContainer = styled(ListItem)(
	() => ({
		zIndex: 2,
		top: "100%",
		right: 0,
		minWidth: "fit-content",
		display: "none",
		position: "absolute",
		// transform: `translate(10%, 0%)`,
	})
);

const MenuListItem = styled(ListItem)(
	({ theme }) => ({
		padding: ".5rem 3rem",
		":hover": {
			backgroundColor:
				theme.palette.action.hover,
		},
	})
);

const StyledNavLink = styled(NavLink)({
	transition: "all 0.3s",
});

type MegaMenuProps = {
	menuList: Navs[];
	title: string;
	url: string;
};
// ===============================================================

const gridSize = (length: number) => {
	if (length === 1) return 12;
	if (length === 2) return 6;
	if (length === 3) return 4;
	return 3;
};

const MegaMenu: FC<MegaMenuProps> = ({
	title,
	url,
	menuList,
}) => {
	const grid = gridSize(
		menuList.length
	);

	//  if menuList.length is bigger then 3 and not an even number, add objects to make it divisable by 3
	if (menuList.length > 3) {
		const remainder =
			menuList.length % 3;
		if (remainder !== 0) {
			for (
				let i = 0;
				i < 3 - remainder;
				i++
			) {
				menuList.push({
					title: "",
					url: "#",
				});
			}
		}
	}

	return (
		<Wrapper>
			<FlexRowCenter
				alignItems='flex-end'
				gap={0.3}>
				<NavLink href={url}>
					{title}
				</NavLink>{" "}
				<KeyboardArrowDown
					sx={{
						color: "grey.500",
						fontSize: "1.1rem",
					}}
				/>
			</FlexRowCenter>

			<MenusContainer className='menu-list'>
				<BazaarCard
					elevation={3}
					sx={{
						mt: 1.5,
						overflow: "hidden",
						minWidth: "fit-content",
						width: grid * 300,
						maxWidth: "100vw",
					}}>
					<Grid container>
						{menuList.map(
							(category, key) => (
								<Grid
									item
									md={grid}
									key={key}
									sx={{
										py: 2,
										":nth-of-type(odd)":
											{
												backgroundColor:
													"grey.50",
											},
									}}>
									<List
										key={category.title}
										sx={{
											flexGrow: 1,
										}}>
										<StyledNavLink
											href={
												category.url
											}>
											<H5
												mb={0.5}
												pl={4}>
												{category.title}
											</H5>
										</StyledNavLink>
										{category?.child?.map(
											(sub) => {
												return (
													<StyledNavLink
														href={
															sub.url
														}
														key={
															sub.title
														}>
														<MenuListItem>
															{
																sub.title
															}
														</MenuListItem>
													</StyledNavLink>
												);
											}
										)}
									</List>
								</Grid>
							)
						)}
					</Grid>
				</BazaarCard>
			</MenusContainer>
		</Wrapper>
	);
};

export default MegaMenu;
