"use client";

import { FlexBox } from "components/flex-box";
import renderNestedNav from "components/renderNestedNav";
import useFashionPreference from "hooks/useFashionPreference";
import { FC } from "react";
import { SECTION_ROOT } from "utils/config";
import { categoryToNavList } from "utils/navigationHelpers";
import MainSectionsMenu from "./MainSectionsMenu";
import NavbarInnerContainer from "./NavbarInnerContainer";
import NavBarWrapper from "./NavbarWrapper";

// ==========================================================
export type NavbarProps = {
	border?: number;
	elevation?: number;
	navListOpen?: boolean;
	hideCategories?: boolean;
};
// ==========================================================

const Navbar: FC<NavbarProps> = ({
	hideCategories = false,
	elevation = 2,
	border = 3,
}) => {
	const { fashionPreference } =
		useFashionPreference();

	const navList = categoryToNavList({
		category: fashionPreference,
		rootCategory: SECTION_ROOT,
	});

	return (
		<NavBarWrapper
			elevation={elevation}
			border={border as number}>
			<NavbarInnerContainer
				sx={{
					justifyContent: hideCategories
						? "center"
						: undefined,
				}}>
				{/* Main Sections */}
				{!hideCategories && (
					<MainSectionsMenu />
				)}

				{/* Horizontal menu */}
				<FlexBox gap={4}>
					{renderNestedNav({
						list: navList
							? navList
							: undefined,
						isRoot: true,
					})}
				</FlexBox>
			</NavbarInnerContainer>
		</NavBarWrapper>
	);
};

export default Navbar;
