import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/VerifiedUser.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/IconButton/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/SvgIcon/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/BazaarCta.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/footer/FooterStyledLink.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/MobileExtraHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/Topbar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layouts/ConditionalFixedHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Logo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/mobile-navigation/MobileNavigationBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Typography.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/BackendVariantsContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/NavigationContext.tsx")