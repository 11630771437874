import { ProductCategory } from "models/ProductCategory.model";
import {
	NavList,
	Navs,
} from "models/NavList.model";

export function categoryToNavList({
	category,
	rootCategory,
}: {
	category: ProductCategory | null;
	rootCategory: string;
}): NavList[] {
	if (
		!category ||
		!category?.category_children.length
	)
		return [];

	return category.category_children.map(
		(child: any) => {
			// Determine whether this child category should have a megaMenu or megaMenuWithSub
			const megaMenu =
				child?.category_children
					?.length > 4;

			const path = `${rootCategory}/${child.handle}`;

			// Recursive call
			const childrenNavs: Navs[] =
				categoryToNavList({
					category: child,
					rootCategory,
				});

			return {
				url: path,
				title: child.name,
				child: childrenNavs,
				megaMenu: megaMenu,
			};
		}
	);
}

export function horizontalToVerticalNavList({
	category,
	rootCategory,
}: {
	category: ProductCategory | null;
	rootCategory: string;
}): NavList[] {
	const vertical = categoryToNavList({
		category,
		rootCategory,
	});
	const horizontal = vertical.reduce(
		(prev: any, curr: any) => {
			const newArr = [...prev];

			if (!curr.child) {
				newArr.push({
					...curr,
					extLink: true,
				});
			} else if (
				curr.megaMenu ||
				curr.megaMenuWithSub
			) {
				const flated =
					curr.child.flat();
				newArr.push({
					title: curr.title,
					url: curr.url,
					child: flated,
				});
			} else {
				newArr.push(curr);
			}

			return newArr;
		},
		[]
	);

	return horizontal;
}
